import React from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import Cooperation from "../../components/Cooperation";
import MortgageCalculator from "../../components/MortgageCalculator";
import ProcessExplanationShort from "../../components/ProcessExplanationShort";
import Faq from "../../components/Faq";
import ProductInCards from "../../components/ProductInCards";
import MediaLogos from "../../components/MediaLogos";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import Article from "../../components/Article";

const Kreditvergleich = () => {
    const { t } = useTranslation();
    const title = () => {
        return (
            <span>
                {`Kreditvergleich in Österreich`} <br /> {`mit miracl findest du die richtige Bank`}
            </span>
        );
    };
    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ProductInCards
                theme="dark"
                useH1
                H1Text={title()}
                hasMarginTop
                CTAUrl={extendUrlWithSourceVersion(urls.survey)}
            />
            <Article hasMarginTop>
                <p>
                    Wenn du einen Kredit aufnimmst, fallen neben dem Abbezahlen der Rate und der Zinsen noch viele
                    weitere Nebenkosten an, an die du im Vorhinein vielleicht nicht denkst. Deshalb müssen beim
                    Kreditvergleich von Anfang an viele Faktoren mitbedacht werden. Der Vergleich von verschiedenen
                    Kreditangeboten kann also eine ganz schön große Aufgabe sein. Damit du dich auf jeden Fall mit dem
                    richtigen Kredit-Wissen auf die Suche nach deinem Kredit machst, haben wir hier die wichtigsten
                    Punkte für dich zusammengetragen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kostenlose Beratung holen
                    </a>
                </p>
                <h2>Welche Banken gibt es in Österreich?</h2>
                <p>
                    GGrundsätzlich sollte man zunächst einmal festhalten, dass es in Österreich sehr viele Banken und
                    Institute gibt, bei denen du einen Kredit beantragen kannst. Davon sind nur die wenigsten den
                    meisten Menschen bekannt. Mit rund 500 Haupt- und 3.100 Zweiganstalten hat Österreich ein besonders
                    dichtes Bankennetz, auch wenn die Zahl aufgrund von Fusionen und Einsparungen ein wenig zurückgeht.
                    Dabei gibt es über 800 ausgewiesene Kreditinstitute, die zu unterschiedlichen Sektoren gerechnet
                    werden.
                </p>
                <p>
                    Die Unterscheidung der jeweiligen österreichischen Kreditinstitute trifft man grundsätzlich aufgrund
                    ihrer Zugehörigkeit zu (ein- oder mehrstufigen) Sektoren und ihrer jeweiligen Rechtsform. Auch die
                    Zugehörigkeit zu einem Dachverband wird dabei beachtet.
                </p>
                <p>
                    Zu dem einstufigen Sektor gezählt werden: Aktienbanken, Landes- und Hypothekenbanken, Bausparkassen
                    und Sonderbanken. Der zwei- oder dreistufige Sektor umfasst Sparkassen und Volksbanken sowie
                    Raiffeisenbanken.
                </p>
                <p>Drei wichtige Unterscheidungen:</p>
                <ul>
                    <li>
                        Großbanken sind flächendeckend im Land vertreten und besitzen oft auch weitere Filialen in
                        anderen Ländern. Durch ihre Größe gleichen sie einem Großunternehmen und gelten auch als
                        Aktiengesellschaften.
                    </li>
                    <li>
                        Sparkassen: Meist regional arbeitende Institute, die aber alle zentrale Dienstleistungen
                        anbieten.
                    </li>
                    <li>
                        Bausparkassen/Hypothekenbanken: Diese Banken sind rechtlich auf Bausparkredite spezialisiert.
                    </li>
                </ul>
                <p>
                    Weitere Kreditinstitute sind Privatbanken, Regionalbanken, Wertpapierhandelsbanken, Banken mit
                    Sonderaufgaben und Onlinebanken.
                </p>
            </Article>
            <Cooperation />
            <Article hasMarginTop>
                <h2>Welche Kreditarten gibt es?</h2>
                <p>
                    Kredit ist nicht gleich Kredit. Auf dem Kreditmarkt gibt es eine Reihe von Finanzierungsoptionen,
                    die sich etwa nach Höhe der Kreditsumme, Laufzeit oder Besicherung unterscheiden.
                </p>
                <p>
                    In Bezug auf die <strong>Höhe</strong> der Kreditsumme ist zunächst zu beachten, dass mit dieser
                    auch die Höhe der Zinsen steigt. Des Weiteren können Kredite in diesem Aspekt wie folgt
                    unterschieden werden:
                </p>
                <ul>
                    <li>
                        <strong>Mikrokredite</strong> richten sich meist an Kreditnehmer*innen, die über eine geringe
                        Bonität und/oder keinerlei Sicherheiten verfügen. Einzelpersonen können diese Kreditart in
                        Österreich im Regelfall bis zu einem Betrag von 12.500 Euro beziehen, Personengesellschaften bis
                        zu einer Summe von 25.000 Euro.
                    </li>
                    <li>
                        Höchstens 10.000 Euro werden im Rahmen von <strong>Kleinkrediten</strong> vergeben. Diese werden
                        häufig auch als
                        <strong>Sofort-</strong> oder <strong>Minikredite</strong> bezeichnet.
                    </li>
                    <li>
                        Nicht exakt bestimmt ist hingegen der Kreditbetrag im Falle von <strong>Mittelkrediten</strong>.
                        Der Begriff verweist zumeist auf Kredite, deren Volumen zwischen jenem von Klein- und
                        Millionenkrediten zu verorten ist.
                    </li>
                    <li>
                        Selbsterklärend ist schließlich die Bezeichnung Millionenkredit. Wenig überraschend sind Kredite
                        ab einem Betrag von einer Million Euro dieser Kategorie zuzuordnen.
                    </li>
                </ul>
                <p>
                    Auch die <strong>Laufzeit</strong> nimmt üblicherweise mit der Höhe des Kreditbetrages zu.
                    Unterschieden werden dabei:
                </p>
                <ul>
                    <li>
                        <strong>Kurzfristige Kredite</strong>, die in einem Zeitraum von bis zu einem Jahr abzubezahlen
                        sind
                    </li>
                    <li>
                        <strong>Mittelfristige Kredite</strong>, deren Laufzeit zwischen einem und vier Jahren beträgt
                    </li>
                    <li>
                        <strong>Langfristige Kredite</strong> mit einer Laufzeit von über vier Jahren
                    </li>
                </ul>
                <p>
                    Ein weiteres Kriterium zur Differenzierung der unterschiedlichen Kreditarten stellt der*die
                    <strong>Kreditgeber*in</strong> dar. Hierbei handelt es sich zumeist um Banken bzw. Bausparkassen,
                    Versicherungen, Arbeitgeber*innen oder Lieferant*innen. Doch auch Privatpersonen und der Staat
                    können als Kreditgeber*innen fungieren.
                </p>
                <p>
                    Im Hinblick auf die <strong>Besicherung</strong> gilt es,{" "}
                    <strong>unbesicherte Blankokredite</strong>, <strong>teilbesicherte Kredite</strong>, die über einen
                    Blankoanteil verfügen, sowie <strong>vollbesicherte Kredite</strong> zu differenzieren. Die teil-
                    und vollbesicherten Varianten können mit mehreren Arten von Sicherheiten abgedeckt werden,
                    beispielsweise durch
                </p>
                <ul>
                    <li>
                        <strong>Real- oder Sachsicherung</strong> (Immobilien, Wertpapiere, Fahrzeuge etc.)
                    </li>
                    <li>
                        <strong>Personensicherheit</strong> bzw. personelle Besicherung (Bürgschaft)
                    </li>
                    <li>
                        <strong>Sicherheits- oder Forderungsabtretung</strong>, bei der die Rechte an einer Forderung
                        von dem*der Schuldner*in an die Kreditgeber*in überschrieben werden
                    </li>
                </ul>
                <p>
                    Eine weitere Möglichkeit, sich den Traum vom Eigenheim zu erfüllen, ist der Bausparvertrag. Anders
                    als das Immobilien- wird das <strong>Bauspardarlehen</strong> vonseiten des Staates gefördert und
                    verfügt deswegen über äußerst günstige Konditionen. Für den Erhalt eines solchen Darlehens ist der
                    Abschluss eines Bausparvertrages obligatorisch, der einen bestimmten Zeitraum – üblicherweise sechs
                    Jahre – festschreibt, in dem Geld angespart wird. Der*die Kreditnehmer*in verfügt nach Ablauf der
                    fixierten Frist über die gesparte Summe, den Betrag des Darlehens sowie über die staatliche Prämie.
                    Die Höhe des Darlehens beträgt dabei maximal 220.000 Euro. Die Wahl des Zinsmodells steht der
                    Kreditnehmer*in hingegen frei, wobei variable Zinsen in diesem Fall über eine Beschränkung nach oben
                    und unten verfügen, was zu einer hohen Planungssicherheit führt. Auch die Laufzeit kann individuell
                    abgestimmt werden und sich auf bis zu 35 Jahre belaufen.
                </p>
                <hr />

                <h2>Worauf muss ich beim Kreditvergleich achten?</h2>
                <h3>Höhe der Rate</h3>
                <p>
                    Die Höhe der Kreditrate ergibt sich aus dem Zinsanteil, der an die Bank fließt, und dem
                    Tilgungsanteil, mit dem du die Restschuld deines Immobilienkredits abträgst. Der Tilgungsanteil wird
                    durch die Angabe der anfänglichen Tilgung fixiert. Der Zinsanteil wird aus dem Zins, zu dem die Bank
                    dir die Finanzierung deiner Immobilie anbietet, errechnet.
                </p>
                <p>
                    <strong>Tipp:</strong> Deine monatliche Kreditrate sollte nicht mehr als 35 % deines Nettoeinkommens
                    betragen. Mit einem <Link to="/dienstleistungen/kreditrechner/">Kreditrechner</Link> kannst du
                    ermitteln, wie hoch deine Rate voraussichtlich ausfällt und dich somit optimal auf die Verhandlungen
                    mit der Bank vorbereiten.
                </p>
                <p>
                    Außerdem empfehlen wir dir, vor den Verhandlungsgesprächen mit deiner Bank eine möglichst
                    detaillierte Haushaltsrechnung zu erstellen. Hierfür musst du nichts weiter tun, als die Summe der
                    Ausgaben während der letzten drei Monate von sämtlichen Einnahmen im selben Zeitraum abzuziehen.
                    Wenn du bereits bei den ersten Terminen mit deiner Bank eine solche Haushaltsrechnung vorlegen
                    kannst, signalisiert du, dass du deine Finanzen fest im Blick hast. Das wirkt sich natürlich positiv
                    auf deine Kreditchancen aus!
                </p>
                <h3>Laufzeit</h3>
                <p>
                    Die Kreditlaufzeit (auch Darlehenslaufzeit) bezeichnet den Zeitraum von der Auszahlung des Kredits
                    bis zu seiner vollständigen Rückzahlung bzw. Tilgung. Die Dauer der Kreditlaufzeit ist abhängig von
                    der Höhe des Nominalzins und der Tilgung. Deshalb ist der Tilgungssatz wichtig und beeinflusst die
                    Laufzeit deines Kredits: Je geringer der Tilgungsbetrag ist, desto länger dauert es, bis du deinen
                    Kredit abbezahlt hast. Die Laufzeit deiner Finanzierung wirkt sich allerdings auch auf deine
                    monatliche Rate aus. Je größer der Zeitraum ist, in dem du deinen Kredit zurückzahlst, umso geringer
                    sind die Raten, die du monatlich an deine Bank entrichten musst. Auch das solltest du bei den
                    Verhandlungsgesprächen immer bedenken.
                </p>
                <h3>Gesamtbelastung</h3>
                <p>
                    Die Gesamtbelastung ergibt sich aus allen Kostenfaktoren, die beim Kredit zusammenkommen. Also
                    bedeutet die Gesamtbelastung die Summe aller Leistungen, die ein Kreditinstitut bei der
                    Kreditvergabe von dir verlangt. Das sind z.B.: Rückzahlungsraten, Zinsen, Provisionen,
                    Bearbeitungsgebühren, etc.
                </p>
                <h3>Tilgung und Sondertilgungen</h3>
                <p>
                    Die Tilgung nennt man die kontinuierliche Rückzahlung deiner Restschuld an das Kreditinstitut.
                    Dieser Betrag wird anfangs vertraglich festgelegt. Je höher – desto schneller ist dein Kredit
                    abbezahlt. Allerdings gibt es auch die Möglichkeit, Sondertilgungen zu zahlen. Dabei kannst du neben
                    deiner monatlichen Rückzahlungsrate zusätzlich einen bestimmten Betrag auf dein Darlehenskonto
                    zahlen. Auch Sondertilgungen werden in ihrer Höhe im Vertrag festgelegt. Wann eine Sondertilgung
                    sinnvoll sein kann, solltest du vorher mit unseren Spezialisten besprechen.
                </p>
                <h3>Eventuell benötigte Versicherungen</h3>
                <p>
                    Beachte, dass die Bank bei der Kreditvergabe möglicherweise von dir das Abschließen einiger
                    Versicherungen verlangt. Informiere dich darüber am besten bei mehreren Versicherungsunternehmen.
                </p>
                <p>
                    Es gibt verschiedene Möglichkeiten, eine Kreditausfallversicherung individuell anzupassen. In der
                    Regel werden drei Arten der Absicherung angeboten:
                </p>
                <ul>
                    <li>Ablebensversicherung</li>
                    <li>Erwerbs- oder Berufsunfähigkeitsversicherung</li>
                    <li>Arbeitslosigkeitsversicherung</li>
                </ul>
                <p>Es ist auch möglich, eine Kombination der drei Arten abzuschließen.</p>
                <p>
                    Die erste Variante, die Ablebensversicherung, wird auch als Risikolebensversicherung bezeichnet.
                    Diese kannst du ganz unabhängig von einem Kredit abschließen. In einigen Fällen akzeptieren Banken
                    in Österreich bereits bestehende Risikolebensversicherungen als Sicherheit für Kredite. Bei einer
                    schlechten Bonität kann es jedoch dazu kommen, dass Banken eine Kreditausfallversicherung als
                    Pflichtvoraussetzung für die Kreditvergabe festlegen.
                </p>
                <p>
                    Eine unverschuldete Arbeitslosigkeit kann jederzeit eintreten und die Tilgung von Krediten
                    gefährden. Eine Kreditausfallversicherung kann in diesem Fall helfen, jedoch ist es wichtig, die
                    Bedingungen des Anbieters genau zu prüfen. Es gibt erhebliche Unterschiede bezüglich der Dauer der
                    Übernahme von Kreditraten und der Karenzzeit. Es ist auch wichtig zu beachten, dass nicht jeder Fall
                    von Arbeitslosigkeit automatisch auch den Versicherungsfall auslöst und dass die Arbeitslosigkeit
                    unvorhergesehen und unverschuldet sein muss.
                </p>
                <h3>Haftungen</h3>
                <p>
                    Haftungen dienen dem Kreditinstitut als Absicherung. Dabei gibt es viele verschiedene Möglichkeiten,
                    die vertraglich bei der Kreditvergabe geregelt werden. Bei diesem Punkt sollte man bereits bei der
                    Erstellung des Vertrages an die Zukunft denken und mit der Bank klären, was im Falle einer Scheidung
                    oder dem Ableben der Personen, die im Vertrag persönliche Haftungen ausgemacht haben, geschieht.
                </p>
                <h3>Zinsen</h3>
                <p>In Bezug auf die Kreditzinsen gilt es vor allem, folgende Begriffe zu unterscheiden:</p>
                <h4>Fixzins</h4>
                <ul>
                    <li>
                        Wie der Name schon sagt, bleibt das Zinsniveau bei einem Fixzinskredit über einen vertraglich
                        fixierten Zeitraum unverändert. Diese Kreditvariante erlaubt es dir daher besser zu planen und
                        deine Ausgaben langfristig zu berechnen. Da Sondertilgungen (das sind zusätzliche Rückzahlungen,
                        die über deine vereinbarte Kreditrate hinausgehen) bei Krediten mit diesem Zinsmodell in der
                        Regel mit einer Pönale von 1 % belegt werden, kannst du einen fix verzinsten Kredit mit weniger
                        Eigenkapital finanzieren.
                    </li>
                </ul>
                <h4>Variabler Zins</h4>
                <ul>
                    <li>
                        Du solltest Kredite mit diesem Zinsmodell nur dann wählen, wenn du über freie Vermögenswerte in
                        der Höhe von mindestens einem Drittel des Kreditvolumens verfügst. Das ist ratsam, um in Zukunft
                        Sondertilgungen tätigen zu können, die die Kreditlaufzeit verkürzen. Auch eine möglichst
                        günstige Entwicklung deines Einkommens ist für die Wahl eines Kredits mit variabler Verzinsung
                        essenziell: Ist doch mit zukünftigen Sollzinssätzen von bis zu 6 % zu kalkulieren.
                    </li>
                </ul>
                <h4>Referenzzinssatz</h4>
                <ul>
                    <li>
                        Der Referenzzinssatz dient am Finanzmarkt zur Orientierung. Er wird von einer neutralen Stelle,
                        die von Land zu Land unterschiedlich ist, täglich für eine bestimmte Währung und Zins-Laufzeit
                        berechnet.
                    </li>
                </ul>
                <h4>Effektivzinssatz</h4>
                <ul>
                    <li>
                        Der Effektivzinssatz berücksichtigt alle Nebenkosten und ist in der Regel etwas höher als der
                        Sollzinssatz. Auch die Länge der Laufzeit und die Höhe der anfallenden Kreditnebenkosten
                        beeinflussen, wie viel du effektiv für deinen Kredit zahlen musst.
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <h3>Wie bekomme ich die nötigen Informationen?</h3>
                <p>
                    Deine Bank ist dazu verpflichtet, dir rechtzeitig vor Abschluss des Vertrages ein
                    Informationsformular zur Verfügung zu stellen, in dem alle Kosten des effektiven Zinssatzes
                    aufgelistet sind. Diese Information ermöglicht einen Vergleich unterschiedlicher Angebote in
                    Österreich, um das für dich beste auszuwählen. Die etlichen Nebenkosten, die zu einem Kredit
                    hinzukommen, sind oft schwer zu überblicken. Lass dich also professionell beraten und nimm dir die
                    nötige Zeit dafür.
                </p>
                <p>
                    Grundsätzlich ist zu beachten, dass beim Immobilienkauf eine Reihe von Nebenkosten anfallen, die du
                    zu Beginn möglicherweise noch nicht zur Gänze überblickt. Dazu zählen:
                </p>
                <ul>
                    <li>
                        Eine Maklerprovision, die ca. 4% des Kaufpreises beträgt und nur in seltenen Fällen eingespart
                        werden kann (wenn keine Makler:in benötigt wird).
                    </li>
                    <li>
                        Im Falle des Hauskaufs musst du für die Eintragung des Eigentumsrechtes in das Grundbuch eine
                        Gebühr von mindestens 1,1 % des Kaufpreises bezahlen. Hinzu kommt eine Eingabegebühr von 44 €.
                        Wird der Kauf, wie allgemein üblich, mithilfe eines Kreditinstituts finanziert, fallen außerdem
                        noch 1,2 % des Pfandbetrags an.
                    </li>
                    <li>
                        Für jede Liegenschaft ist außerdem eine Grunderwerbssteuer von 3,5% des Grundstückswerts zu
                        bezahlen.
                    </li>
                    <li>
                        Die Notar- oder Anwaltskosten sind ebenfalls unvermeidbar, genauso wie die Finanzierungskosten,
                        wenn du einen Kredit bei einer Bank oder einer Bausparkasse aufnimmst. Hier kannst du mit
                        maximal 3% des Kreditvolumens rechnen.
                    </li>
                </ul>
                <h3>Wie hoch sind die Kreditzinsen in Österreich momentan?</h3>
                <p>
                    Bei einem Kreditvergleich in Österreich ist die Frage nach den Zinsen natürlich oft die
                    entscheidende. Derzeit sind die Zinsen in Österreich grundsätzlich ziemlich niedrig. Die
                    Nominalzinsen bewegen sich bei variablen Zinssätzen ca. zwischen 3,63 - 4,0 % und bei Fixzinssätzen
                    zwischen 3,6 - 3,9 %. Jedoch ist der konkrete Zinssatz abhängig von der Kredithöhe, der Laufzeit,
                    deinen Eigenmitteln, deiner Bonität und zahlreichen anderen Faktoren.
                </p>
                <h3>Wie finde ich den günstigsten Kredit?</h3>
                <p>
                    Welcher Kredit in deiner Situation am günstigsten ist, hängt ganz von deinen individuellen Umständen
                    ab. Denn es gibt nicht die eine Bank, die die günstigsten Kredite gibt. Manche Banken geben günstige
                    Kredite mit variablen Zinsen, andere wiederum die besseren Fixzinsskredite. An einem Kreditvergleich
                    unterschiedlicher österreichischer Banken kommst du also nicht vorbei, wenn du das richtige Angebot
                    finden möchtest. Durch individuelle Beratung, kommst du zu dem für dich persönlich günstigsten
                    Kreditangebot.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum kostenlosen Kreditvergleich
                    </a>
                </p>
            </Article>
            <MortgageCalculator hasMarginTop defaultTerm={35} defaultMortgage={320000} hasGreyBg />
            <ProcessExplanationShort hasMarginTop />
            <MediaLogos hasGreyBg hasMarginTop />
            <Faq hasMarginTop ctaText="Mehr Antworten" ctaUrl="/faq" />
            <BreadcrumbList page={"kreditvergleich"}></BreadcrumbList>
        </Layout>
    );
};

export default Kreditvergleich;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: {
                    in: [
                        "header"
                        "footer"
                        "mortgageCalc"
                        "page.dienstleistungen.kreditvergleich"
                        "kreditrechnerFaq"
                    ]
                }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
