import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import { ButtonLink } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";

const ProductInCards = ({ theme, useH1, hasMarginTop, CTAUrl, H1Text }) => {
    const { cardsImageDesktop, cardsImageMobile } = useStaticQuery(graphql`
        query {
            cardsImageDesktop: file(relativePath: { eq: "product-in-cards-desktop.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF], height: 550)
                }
            }
            cardsImageMobile: file(relativePath: { eq: "product-in-cards-mobile.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF], height: 850)
                }
            }
        }
    `);

    const Title = ({ children }) => {
        if (useH1) return <h1 className="h1">{children}</h1>;
        return <h2 className="h1">{children}</h2>;
    };

    return (
        <Wrapper hasMarginTop={hasMarginTop} theme={theme}>
            {H1Text ? (
                <Title>{H1Text}</Title>
            ) : (
                <Title>
                    Die smarteste Art Immobilienkredite
                    <br />
                    zu vergleichen.
                </Title>
            )}

            <ImageWrapperDesktop image={getImage(cardsImageDesktop)} />
            <ButtonLink secondary href={CTAUrl} arrowRight>
                Zum Angebot
            </ButtonLink>
            <ImageWrapperMobile image={getImage(cardsImageMobile)} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: ${({ theme }) => (theme === "dark" ? "#1E40AF" : "#fff")};
    font-family: "Inter", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    line-height: 1.5;
    padding: 50px 0 0;
    text-align: center;

    .h1 {
        color: ${({ theme }) => (theme === "dark" ? "#fff" : "#111")};
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.4;
        margin: 0 0 25px;
        padding: 0 12px;

        @media (min-width: ${breakpoints.tabletBigMin}) {
            font-size: 32px;
            margin-bottom: 0;
        }
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        padding-bottom: 100px;
        padding-top: 100px;
    }
`;
const ImageWrapperMobile = styled(GatsbyImage)`
    margin-top: 10px;
    display: block;
    &.gatsby-image-wrapper-constrained {
        display: block;
    }

    &.gatsby-image-wrapper img {
        margin: 0 auto;
        width: auto;
    }
    @media (min-width: ${breakpoints.tabletBigMin}) {
        display: none;
        &.gatsby-image-wrapper-constrained {
            display: none;
        }
    }
`;

const ImageWrapperDesktop = styled(GatsbyImage)`
    margin: 8px 10px 0;
    min-height: 550px;
    display: none;
    &.gatsby-image-wrapper-constrained {
        display: none;
    }
    &.gatsby-image-wrapper img {
        height: 550px;
        width: auto;
        margin: 0 auto;
    }
    @media (min-width: ${breakpoints.tabletBigMin}) {
        display: block;
        &.gatsby-image-wrapper-constrained {
            display: block;
        }
    }
`;

export default ProductInCards;
